import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as React from 'react';

interface Props {
  readOnly?: boolean;
  label: string;
  value?: string;
  onChange: () => void;
  checked: boolean;
}

const CheckBoxField = ({ readOnly, label, onChange, value, checked }: Props) => {
  return (
    <FormControlLabel
      control={<Checkbox disabled={readOnly} checked={checked} onChange={onChange} value={value} />}
      label={label}
    />
  );
};

export default CheckBoxField;
