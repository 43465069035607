import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { OptionType } from './AutosuggestContainer';

interface Props {
  chipList: Set<OptionType>;
  handleDelete?: (value: OptionType) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5)
    }
  })
);

const ChipListContainer = ({ chipList, handleDelete }: Props) => {
  const classes = useStyles();
  return (
    <div>
      {Array.from(chipList, entry => (
        <Chip
          key={entry.label}
          label={entry.label}
          onDelete={handleDelete && (() => handleDelete(entry))}
          className={classes.chip}
        />
      ))}
    </div>
  );
};

export default ChipListContainer;
