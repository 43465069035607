import * as React from 'react';
import documentsStep from '../shared/components/documents';
import { RegistrationStep } from '../shared/components/RegistrationStepper';
import keyDataStep from '../shared/components/registrationSteps/personalData';
import Summary from '../shared/components/registrationSteps/summary/Summary';
import workExperienceStep from './components/registrationSteps/positionCriteria';

const summaryStep: RegistrationStep<any> = {
  title: 'Registrierung abgeschlossen',
  component: () => <Summary websiteLink="https://www.senator-partners.de/" />,
  saveProgress: false,
  noProfile: true,
  noBackButton: true
};

export default [keyDataStep, workExperienceStep, documentsStep, summaryStep];
