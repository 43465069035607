import { performGet, performPut } from 'core/apiUtils';

export interface PositionCriteriaDataType {
  levels: string[];
  branches: OptionType[];
  positionDescription: OptionType[];
  dailyRate: number;
  isPermanentEmployment: boolean;
}

interface OptionType {
  category: string;
  label: string;
}

export async function getPositionCriteria() {
  return performGet<PositionCriteriaDataType>('/positionCriteria');
}

export async function updatePositionCriteria(profile: PositionCriteriaDataType) {
  return performPut<void>('/positionCriteria', profile);
}
