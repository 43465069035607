import { createStyles, makeStyles, MenuItem, Paper, TextField, Theme } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { useState } from 'react';
import Autosuggest, { OnSuggestionSelected } from 'react-autosuggest';

export interface OptionType {
  category: string;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 250,
      flexGrow: 1
    },
    container: {
      position: 'relative'
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0
    },
    suggestion: {
      display: 'block'
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none'
    },
    divider: {
      height: theme.spacing(2)
    }
  })
);

function renderInputComponent(inputProps: any) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion: OptionType, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) {
  const matches = match(`${suggestion.label} (${suggestion.category})`, query);
  const parts = parse(`${suggestion.label} (${suggestion.category})`, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 600 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value: string, suggestions: OptionType[]) {
  const inputValue = value.trim().toLowerCase(); // TODO here was some deburr function from lodash https://lodash.com/docs/4.17.11#deburr
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 || inputLength < 2
    ? []
    : suggestions.filter(suggestion => {
        const keep = count < 5 && suggestion.label.toLowerCase().includes(inputValue);

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

interface Props {
  label: string;
  placeholder: string;
  optionList: OptionType[];
  onSelect: (value: OptionType) => void;
}

export const AutosuggestContainer = ({ label, placeholder, optionList, onSelect }: Props) => {
  const [state, setState] = useState({
    single: ''
  });
  const [stateSuggestions, setSuggestions] = useState<OptionType[]>([]);
  const classes = useStyles();

  const handleSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestions(getSuggestions(value, optionList));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (name: keyof typeof state) => (
    event: React.ChangeEvent<{}>,
    { newValue }: Autosuggest.ChangeEvent
  ) => {
    setState({
      single: newValue
    });
  };

  const getSuggestionValue = (suggestion: OptionType) => {
    return suggestion.label;
  };

  const onSuggestionSelected: OnSuggestionSelected<OptionType> = (event, { suggestion }) => {
    event.preventDefault()
    onSelect(suggestion);
    setState({
      single: ''
    });
  };

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected
  };
  return (
    <Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        id: 'react-autosuggest-simple',
        label,
        placeholder,
        value: state.single,
        onChange: handleChange('single')
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion
      }}
      renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
    />
  );
};

export default AutosuggestContainer;
