import Slider from '@material-ui/core/Slider';
import React from 'react';

interface Props {
  min?: number;
  max?: number;
  marks?: Array<{ value: number; label: string }>;
  step?: number;
  value: number | number[];
  handleChange: (event: any, newValue: number | number[]) => void;
}

const RangeSlider = ({ handleChange, value, ...other }: Props) => {
  return (
    <Slider
      value={value}
      onChange={handleChange}
      aria-labelledby="range-slider"
      valueLabelDisplay="auto"
      {...other}
      style={{ marginRight: '70px' }}
    />
  );
};

export default RangeSlider;
