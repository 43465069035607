import { FormControl, FormGroup, FormHelperText, FormLabel, Grid } from '@material-ui/core';
import React from 'react';

interface Props {
  children: React.ReactNode;
  labelText?: string;
  helperText?: string;
  error?: boolean;
}

const CheckBoxFromGroup = ({ children, labelText, helperText, error }: Props) => {
  return (
    <Grid item xs={12}>
      <FormControl error={error} fullWidth>
        <FormLabel component="legend">{labelText}</FormLabel>
        <FormGroup>{children}</FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default CheckBoxFromGroup;
