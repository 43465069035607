import { Box, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TOOLTIPS from 'interim/static_data/tooltips';
import React from 'react';
import { OptionType } from '../../../../core/components/generic_components/AutosuggestContainer';
import SearchAndSelectContainer from '../../../../core/components/generic_components/SearchAndSelectContainer';
import { BRANCHES } from '../../../static_data/branches';

interface Props {
  readOnly?: boolean;
  invalid?: boolean;
  updateSelection: (selection: Set<OptionType>) => void;
  selectedIndustrySectors: Set<OptionType>;
}

const IndustrySectorsSelectionField = ({ readOnly, invalid, selectedIndustrySectors, updateSelection }: Props) => {
  return (
    <div>
      <Box pb={1}>
        <Typography variant="h6" component="h6" color={invalid ? 'error' : undefined}>
          Branchen &nbsp;
          <Tooltip title={TOOLTIPS.industrySectors} disableFocusListener disableTouchListener>
            <InfoIcon style={{ verticalAlign: 'sub' }} />
          </Tooltip>
        </Typography>
      </Box>
      <SearchAndSelectContainer
        readOnly={readOnly}
        selectedOption={selectedIndustrySectors}
        suggestions={BRANCHES}
        onChange={(selectedOptions: Set<OptionType>) => {
          updateSelection(selectedOptions);
        }}
        placeholderText='z.B "Glas" oder "bau"'
        searchFieldLabel="Suche..."
      />
    </div>
  );
};

export default React.memo(IndustrySectorsSelectionField);
