import { Box, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TOOLTIPS from 'interim/static_data/tooltips';
import React from 'react';
import { OptionType } from '../../../../core/components/generic_components/AutosuggestContainer';
import SearchAndSelectContainer from '../../../../core/components/generic_components/SearchAndSelectContainer';
import { FUNCTIONS } from '../../../static_data/functions';

interface Props {
  readOnly?: boolean;
  invalid?: boolean;
  updateSelection: (selection: Set<OptionType>) => void;
  selectedWorkFunctions: Set<OptionType>;
}

const WorkFunctionSelectionField = ({ readOnly, invalid, selectedWorkFunctions, updateSelection }: Props) => {
  return (
    <div>
      <Box pb={1}>
        <Typography variant="h6" component="h6" color={invalid ? 'error' : undefined}>
          Funktionen &nbsp;
          <Tooltip title={TOOLTIPS.workFunctions} disableFocusListener disableTouchListener>
            <InfoIcon style={{ verticalAlign: 'sub' }} />
          </Tooltip>
        </Typography>
      </Box>
      <SearchAndSelectContainer
        readOnly={readOnly}
        selectedOption={selectedWorkFunctions}
        suggestions={FUNCTIONS}
        onChange={(selectedOptions: Set<OptionType>) => {
          updateSelection(selectedOptions);
        }}
        placeholderText='z.B "Sach" oder "ing"'
        searchFieldLabel="Suche..."
      />
    </div>
  );
};

export default React.memo(WorkFunctionSelectionField);
