import { FormControlLabel, FormGroup, Switch, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TOOLTIPS from 'interim/static_data/tooltips';
import React from 'react';

interface Props {
  value: boolean;
  onClick: (value: boolean) => void;
  readOnly: boolean | undefined;
}

const PermanentPositionToggle = ({ value, onClick, readOnly }: Props) => {
  const positionRespones = readOnly ? `${value ? 'Ja' : 'Nein'}` : '';
  return (
    <div>
      <Typography variant="h6" component="h6">
        Interesse an einer permanenter Festanstellung: {positionRespones}
        &nbsp;
        <Tooltip title={TOOLTIPS.permanentPosition} disableFocusListener disableTouchListener>
          <InfoIcon style={{ verticalAlign: 'sub' }} />
        </Tooltip>
      </Typography>
      {!readOnly && (
        <FormGroup row>
          <FormControlLabel
            control={<Switch checked={value} onChange={() => onClick(!value)} value={value} />}
            label={`${value ? 'Ja' : 'Nein'}`}
          />
        </FormGroup>
      )}
    </div>
  );
};

export default React.memo(PermanentPositionToggle);
