import { Box, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TOOLTIPS from 'interim/static_data/tooltips';
import React from 'react';
import RangeSlider from '../../../../core/components/generic_components/RangeSlider';

interface Props {
  readOnly?: boolean;
  invalid?: boolean;
  onChange: (newValue: number) => void;
  dailyRateValue: number;
}

const DailyRateSlider = ({ readOnly, invalid, onChange, dailyRateValue }: Props) => {
  const handleChange = (event: any, newValue: number) => {
    onChange(newValue);
  };

  const formatIntValueToEuroNotation = (input: number | number[]) => {
    const formatOptions = { style: 'currency', currency: 'EUR' };
    if (Array.isArray(input)) {
      const formattedInput0 = Intl.NumberFormat('de-DE', formatOptions).format(input[0]);
      const formattedInput1 = Intl.NumberFormat('de-DE', formatOptions).format(input[1]);
      const max = Intl.NumberFormat('de-DE', formatOptions).format(3000);
      if (input[0] === input[1]) {
        if (input[0] === 3001) {
          return `über ${max}`;
        }
        return `${formattedInput0}`;
      }
      return `zwischen ${formattedInput0} und ${input[1] > 3000 ? `über ${max}` : formattedInput1}`;
    }
    if (input > 3000) {
      return `über ${Intl.NumberFormat('de-DE', formatOptions).format(3000)} `;
    }
    return `${Intl.NumberFormat('de-DE', formatOptions).format(input)} `;
  };

  return (
    <div>
      <Box pb={3}>
        <Typography variant="h6" component="h6" color={invalid ? 'error' : undefined}>
          Mindesttagessatz: {dailyRateValue ? formatIntValueToEuroNotation(dailyRateValue) : '0,00 €'}
          &nbsp;
          <Tooltip title={TOOLTIPS.dailyRate} disableFocusListener disableTouchListener>
            <InfoIcon style={{ verticalAlign: 'sub' }} />
          </Tooltip>
        </Typography>
      </Box>
      {!readOnly && (
        <Box m={5} pb={1}>
          <RangeSlider
            value={dailyRateValue}
            handleChange={handleChange}
            min={0}
            max={3001}
            marks={[
              { value: 0, label: `${formatIntValueToEuroNotation(0)}` },
              { value: 3001, label: `über ${formatIntValueToEuroNotation(3000)}` }
            ]}
            step={50}
          />
        </Box>
      )}
    </div>
  );
};

export default React.memo(DailyRateSlider);
