import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CheckBoxField from '../../../../core/components/generic_components/CheckBoxField';
import CheckBoxFormGroup from '../../../../core/components/generic_components/CheckBoxFormGroup';

export interface CheckBoxElement {
  label: string;
  checked: boolean;
}

interface Props {
  readOnly?: boolean;
  invalid?: boolean;
  updateSelection: (selection: CheckBoxElement[]) => void;
  levels: CheckBoxElement[];
}

const HierarchyLevelFormGroup = ({ readOnly, invalid, updateSelection, levels }: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <Box pb={1}>
        <Typography variant="h6" component="h6" color={invalid ? 'error' : undefined}>
          Hierarchieebenen
        </Typography>
      </Box>

      <CheckBoxFormGroup>
        {levels.map((entry, index) => (
          <CheckBoxField
            readOnly={readOnly}
            key={entry.label}
            label={entry.label}
            value={entry.label}
            checked={entry.checked}
            onChange={() => {
              const newLevelCheckedList = [...levels];
              newLevelCheckedList[index].checked = !newLevelCheckedList[index].checked;

              updateSelection(newLevelCheckedList);
            }}
          />
        ))}
      </CheckBoxFormGroup>
    </div>
  );
};

export default React.memo(HierarchyLevelFormGroup);
