import { getPositionCriteria, PositionCriteriaDataType, updatePositionCriteria } from 'interim/api/positionCriteria';
import { RegistrationStep } from '../../../../shared/components/RegistrationStepper';
import { HIERARCHY_LEVELS } from '../../../static_data/levels';
import PositionCriteria, { PositionCriteriaState } from './PositionCriteria';

const workExperienceStep: RegistrationStep<PositionCriteriaState, PositionCriteriaValidation> = {
  title: 'Erfahrung / Tagessatz',
  component: PositionCriteria,
  initialState: {
    selectedIndustrySectors: new Set(),
    selectedWorkFunctions: new Set(),
    selectedHierarchyLevels: [],
    dailyRate: 0,
    isPermanentEmployment: false
  },
  onLoad: () => getPositionCriteria().then(mapFromServerData),
  onSave: (state: PositionCriteriaState) => updatePositionCriteria(mapToServerData(state)),
  onValidate: state => {
    const result: PositionCriteriaValidation = {
      industrySectorsValid: !!state.selectedIndustrySectors.size,
      workFunctionsValid: !!state.selectedWorkFunctions.size,
      hierarchyLevelsValid: !!state.selectedHierarchyLevels.find(cb => cb.checked),
      dailyRateValid: state.dailyRate > 0
    };
    return result.industrySectorsValid &&
    result.workFunctionsValid &&
    result.hierarchyLevelsValid &&
    result.dailyRateValid
      ? undefined
      : result;
  }
};

export interface PositionCriteriaValidation {
  industrySectorsValid: boolean;
  workFunctionsValid: boolean;
  hierarchyLevelsValid: boolean;
  dailyRateValid: boolean;
}

function mapFromServerData(data: PositionCriteriaDataType): PositionCriteriaState {
  return {
    selectedIndustrySectors: new Set(data.branches),
    selectedWorkFunctions: new Set(data.positionDescription),
    selectedHierarchyLevels: HIERARCHY_LEVELS.map(entry => {
      return { label: entry, checked: data.levels.includes(entry) };
    }),
    dailyRate: data.dailyRate,
    isPermanentEmployment: data.isPermanentEmployment
  };
}

function mapToServerData(state: PositionCriteriaState): PositionCriteriaDataType {
  return {
    levels: state.selectedHierarchyLevels.filter(entry => entry.checked).map(entry => entry.label),
    branches: Array.from(state.selectedIndustrySectors),
    positionDescription: Array.from(state.selectedWorkFunctions),
    dailyRate: state.dailyRate,
    isPermanentEmployment: state.isPermanentEmployment
  };
}

export default workExperienceStep;
