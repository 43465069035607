export default {
  dailyRate:
    'Die Angabe des Mindesttagessatzes dient lediglich dazu, Ihnen möglichst zielgerichtete Interim Mandate anzubieten. Bitte beachten Sie, dass Sie bei einem zu hohen Mindesttagessatz ggf. nicht bei passenden Vakanzen benachrichtigt werden, wenn Ihr Tagessatz zu weit von den Vorstellungen unseres Mandanten abweicht. Die tatsächliche Vergütung wird jeweils bezogen auf das konkrete Mandat verhandelt.',
  permanentPosition:
    'Aktivieren Sie diese Box, wenn Sie auch bei passenden Vakanzen in Festanstellung von uns benachrichtigt werden wollen.',
  workFunctions:
    'Bitte wählen Sie die von Ihnen abgedeckten Funktionen möglichst zielgenau. Je mehr Funktionen Sie auswählen, desto mehr unpassende Interim Mandate bekommen Sie ggf. angeboten.',
  industrySectors:
    'Bitte wählen Sie die von Ihnen abgedeckten Branchen möglichst zielgenau. Je mehr Branchen Sie auswählen, desto mehr unpassende Interim Mandate bekommen Sie ggf. angeboten.'
};
