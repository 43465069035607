import { Grid } from '@material-ui/core';
import React from 'react';
import { AutosuggestContainer, OptionType } from './AutosuggestContainer';
import ChipListContainer from './ChipListContainer';

interface Props {
  readOnly?: boolean;
  selectedOption: Set<OptionType>;
  suggestions: OptionType[];
  onChange: (option: Set<OptionType>) => void;
  placeholderText: string;
  searchFieldLabel: string;
}

const SearchAndSelectContainer = ({
  readOnly,
  selectedOption,
  onChange,
  suggestions,
  placeholderText,
  searchFieldLabel
}: Props) => {
  const onSelect = (value: OptionType) => {
    const newSelectedOption = new Set(selectedOption);
    newSelectedOption.add(value);
    onChange(newSelectedOption);
  };

  const onDelete = (value: OptionType) => {
    const newSelectedOption = new Set(selectedOption);
    newSelectedOption.delete(value);
    onChange(newSelectedOption);
  };

  const selectedOptionsArray = Array.from(selectedOption);
  return (
    <Grid container spacing={3}>
      {!readOnly && (
        <Grid item xs={12}>
          <AutosuggestContainer
            optionList={suggestions.filter(
              suggestion =>
                !selectedOptionsArray.find(
                  opt => opt.category === suggestion.category && opt.label === suggestion.label
                )
            )}
            label={searchFieldLabel}
            placeholder={placeholderText}
            onSelect={onSelect}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <div>
          <ChipListContainer chipList={selectedOption} handleDelete={readOnly ? undefined : onDelete} />
        </div>
      </Grid>
    </Grid>
  );
};

export default SearchAndSelectContainer;
