import { Grid } from '@material-ui/core';
import { useNestedStateFn } from 'core/utils/useNestedState';
import React from 'react';
import { PositionCriteriaValidation } from '.';
import { OptionType } from '../../../../core/components/generic_components/AutosuggestContainer';
import { StepComponentProps } from '../../../../shared/components/RegistrationStepper';
import DailyRateSlider from './DailyRateSlider';
import HierarchyLevelFormGroup, { CheckBoxElement } from './HierarchyLevelFormGroup';
import IndustrySectorsSelectionField from './IndustrySectorsSelectionField';
import PermanentPositionToggle from './PermanentPositionToggle';
import WorkFunctionSelectionField from './WorkFunctionSelectionField';

interface Props extends StepComponentProps<PositionCriteriaState, PositionCriteriaValidation> {}

export interface PositionCriteriaState {
  selectedIndustrySectors: Set<OptionType>;
  selectedWorkFunctions: Set<OptionType>;
  selectedHierarchyLevels: CheckBoxElement[];
  dailyRate: number;
  isPermanentEmployment: boolean;
}

const PositionCriteria = ({
  readOnly,
  state,
  setState,
  validationResult = {
    industrySectorsValid: true,
    workFunctionsValid: true,
    hierarchyLevelsValid: true,
    dailyRateValid: true
  }
}: Props) => {
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <IndustrySectorsSelectionField
          readOnly={readOnly}
          invalid={!validationResult.industrySectorsValid}
          selectedIndustrySectors={state.selectedIndustrySectors}
          updateSelection={useNestedStateFn(setState, 'selectedIndustrySectors')}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <WorkFunctionSelectionField
          readOnly={readOnly}
          invalid={!validationResult.workFunctionsValid}
          selectedWorkFunctions={state.selectedWorkFunctions}
          updateSelection={useNestedStateFn(setState, 'selectedWorkFunctions')}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <HierarchyLevelFormGroup
          readOnly={readOnly}
          invalid={!validationResult.hierarchyLevelsValid}
          updateSelection={useNestedStateFn(setState, 'selectedHierarchyLevels')}
          levels={state.selectedHierarchyLevels}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <DailyRateSlider
          readOnly={readOnly}
          invalid={!validationResult.dailyRateValid}
          onChange={useNestedStateFn(setState, 'dailyRate')}
          dailyRateValue={state.dailyRate}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <PermanentPositionToggle
          readOnly={readOnly}
          value={state.isPermanentEmployment}
          onClick={useNestedStateFn(setState, 'isPermanentEmployment')}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(PositionCriteria);
