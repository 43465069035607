import React from 'react';
import { listDocuments, UploadedFile } from 'shared/api/document';
import { submitProfile } from 'shared/api/progress';
import { RegistrationStep } from '../RegistrationStepper';
import DocumentsUpload from './DocumentsUpload';

const documentsStep: RegistrationStep<UploadedFile[], false> = {
  title: 'Dokumente',
  component: props => <DocumentsUpload {...props} />,
  initialState: [],
  onSave: () => submitProfile(),
  onLoad: listDocuments,
  onValidate: state => !!(state || []).length
};

export default documentsStep;
